import React, { useEffect, useState } from 'react';
import useClass from '../../hooks/useClass';
import { 
  Spinner, 
  Card, 
  CardHeader, 
  CardBody, 
  Nav, 
  NavItem, 
  NavLink, 
  TabContent, 
  TabPane, 
  Row, 
  Col, 
  Input,
} from 'reactstrap';
import { applicationData, fieldStatusConstants, fieldVerificationDropdown, loanData, reKycFieldData, rupeeSymbol } from '../../utils/constants';
import CustomUncontrolledDropdown from '../../components/Dropdown/CustomUncontrolledDropdown';
import LoadingButton from '../../components/Common/LoadingButton';
import Table from '../../components/Common/Table';
import lenderColumns from './LenderColumns';
import ActionDropdown from '../../components/Dropdown/ActionDropdown';
import { useSelector } from 'react-redux';
import { updateActiveApplicationIndex, updateApplicationList, updateSaasApplicationStatus } from '../../store/application/applicationAction';
import { getTeamList } from '../../store/admin/adminData';
import { Link, useLocation } from 'react-router-dom';
import { getEligibleLendersOfApplication } from '../../store/application/customer';

const Applications = ({ 
    customerCode,
    handleAction,
    setEntityFinancialData,
    handleSendToLender,
    isRekycOpen,
    handleRekycFieldsSelection,
    reKycFields,
    handleFieldAction
  }) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const applicationId = queryParams.get('applicationId');
    const { toaster, server, permission, adminServer, dispatch } = useClass();
    const { fieldStatus } = useSelector((state) => (state?.actionFields));
    const {list: teamList, loading: teamListLoading} = useSelector((state) => (state?.team));
    const {list: lenderList, loading: eligibleLenderLoader} = useSelector((state) => (state?.eligibleLenders))
    const {data: logsData, loading: logsLoader, error: logsError} = useSelector((state) => (state?.applicationLogs));
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeApplicationTab, setactiveApplicationTab] = useState(0);
    const [activeLoanTab, setActiveLoanTab] = useState(0);
    const [assignedTo, setAssignedTo] = useState({});
    const [assignedTeam, setAssignedTeam] = useState({});
    const columns = lenderColumns(
      permission, 
      handleSendToLender, 
      applications[activeApplicationTab]?.applicationId, 
      applications?.[activeApplicationTab]?.loan?.[activeLoanTab]?.loanUid
    );
    const [newLenderList, setNewLenderList] = useState([]); 
    
    useEffect(() => {
     getCustomerApplications();
    }, []);

    useEffect(() => {
      setAssignedTo({
        userName: applications?.[activeApplicationTab]?.userName, 
        userId: applications?.[activeApplicationTab]?.userId,
        teamName: applications?.[activeApplicationTab]?.teamName,
        teamId: applications?.[activeApplicationTab]?.teamId
      });
      const arr = lenderList?.filter((obj) => (
        (obj?.type === 'LENDER' && applications?.[activeApplicationTab]?.productType === 'LOAN') || 
        (obj?.type === 'INSURER' && applications?.[activeApplicationTab]?.productType === 'INSURANCE'))
      );
      computeChildLoanData(arr);
    }, [applications, activeApplicationTab, activeLoanTab, lenderList]);

    useEffect(() => {
      teamList?.forEach((team) => {
        if(team?.id === applications?.[activeApplicationTab]?.teamId){
          const members = team?.members?.map((member) => ({...member, label: member.name, value: member.userId}))
          let data = {...team};
          data.members = members;
          setAssignedTeam(data);
        }
      })
    }, [teamList, activeApplicationTab]);

    const getCustomerApplications = async () => {
      try {
        const response = await server.getCustomerApplicationsDetails(customerCode);
        setLoading(false);
        if(response?.data?.success){
          setApplications(response?.data?.data);
          dispatch(updateApplicationList(response?.data?.data));
          setEntityFinancialData(response?.data?.data?.[0]?.additionalData);
          response?.data?.data?.forEach((application, index) => {
            if(application?.applicationId === applicationId){
              dispatch(updateActiveApplicationIndex(index));
              setactiveApplicationTab(index);
              dispatch(getEligibleLendersOfApplication(response?.data?.data?.[index]?.applicationId));
            }
          })
        } else {
          throw new Error(response?.data?.message);
        }
      } catch(error){
          toaster.show(error?.message);
          setLoading(false);
        }
    }
    
    const toggleApplicationTab = (tab) => {
      return () => {
        if (activeApplicationTab !== tab) {
          setactiveApplicationTab(tab);
          dispatch(updateActiveApplicationIndex(tab));
          dispatch(getEligibleLendersOfApplication(applications?.[tab]?.applicationId));
          setNewLenderList([]);
        }
      }
    };

    const toggleLoanTab = (tab) => {
      return () => {
        if(activeLoanTab !== tab){
          setActiveLoanTab(tab);
        }
      }
    }

    const computeChildLoanData = (arr) => {
      if(applications[activeApplicationTab] && arr.length > 0){
        let newLenders = [];
        for(const lender of arr){
          let newObj = {...lender};
          if(applications[activeApplicationTab]?.loan?.[activeLoanTab]?.childLoans && applications[activeApplicationTab]?.productType === 'LOAN'){
            for(const child of applications[activeApplicationTab]?.loan?.[activeLoanTab]?.childLoans){
              if(child?.lenderCode == lender.code){
                newObj = {...newObj, childLoanId: child?.loanUid, applicationSent: true, status: child.statusCode}
              }
            }
          } else {
  
          }

          newLenders = [...newLenders, newObj];
        }
        setNewLenderList(newLenders);
      }
    }

    const handleUserAssignment = (user) => {
      return () => {
        if(permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER){
          handleAppliationAssignment(applications[activeApplicationTab]?.applicationId, user)
        } else {
          toaster.show(true, 'You dont have permission');
        }
      }
    }

    const handleTeamAssignment = (team) => {
      return async () => {
        try {
          if(permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER){
            const result = await toaster.confirmation('Assign', `Assign ${applications[activeApplicationTab]?.applicationId} to ${team?.name}`);
            if(result.isConfirmed){
              const response = await adminServer.assignApplication({teamId: team?.id, applicationIds: [applications[activeApplicationTab]?.applicationId]});
              if(response?.data?.success){
                toaster.show(false, 'Assigned Successfully');
                window.location.reload();
              } else {
                throw new Error(response?.data?.message);
              }
            }
          } else {
            throw new Error('You dont have permission');
          }
        } catch(error) {
          toaster.show(true, error?.message);
        }
      }
    }

    const handleAppliationAssignment = async (applicationId, user) => {
      try {
        const result = await toaster.confirmation('Assign', `Assign ${applicationId} to ${user?.name}`);
        if(result?.isConfirmed){
          const response = await adminServer.assignApplication({teamId: applications[activeApplicationTab]?.teamId, userId: user?.userId, applicationIds: [applicationId]});
          if(response?.data?.success){
            toaster.show(false, 'Assigned Successfully');
            window.location.reload();
          } else {
            throw new Error(response?.data?.message);
          }
        }
      } catch(error){
        toaster.show(true, error?.message);
      }
    }

    const handleActionButtonClick = (action) => {
      return async () => {
        if(applications?.[activeApplicationTab]?.productType == 'SOFTWARE') {
          const buttonCont = action?.includes('REJECT') ? 'Reject' : action?.includes('HOLD') ? 'Hold' : 'Approve';
          const result = await toaster.confirmation(buttonCont, `${buttonCont} ${applications?.[activeApplicationTab]?.productName}`);
          if(result?.isConfirmed){
            dispatch(updateSaasApplicationStatus({action, softwareUid: applications[activeApplicationTab]?.software?.[0]?.softwareUid}))
          }
        } else {
          handleAction(
            action, 
            applications[activeApplicationTab]?.loan?.[activeLoanTab]?.loanUid, 
            applications?.[activeApplicationTab]?.productCode,
            applications?.[activeApplicationTab]?.applicationId
          )
        }
      }
    }

    const fetchTeamList = (search) => {
      if(permission?.TEAMS?.LIST?.VIEW_TEAM_LIST_ALL){
        dispatch(getTeamList({search}));
      }
    }

    return (
        <>
          <Card className='common-card-style'>
            <CardHeader>
              <div className='section-heading'>Applications</div>             
            </CardHeader>
            <CardBody>
            { loading ? 
              <>
                <Spinner></Spinner>
              </> 
              : 
              <>
                <Nav tabs>
                  {applications.map((application, index) => (
                    <NavItem key={`applictions${index}`}>
                      <NavLink 
                        style={{color: activeApplicationTab === index && 'green' }} 
                        className={`${activeApplicationTab === index && 'active'} clickable`} 
                        onClick={toggleApplicationTab(index)}
                      >
                        <i className="dripicons-mail me-1 align-middle"> </i>{" "}
                        {application?.productName} - {application?.applicationId}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>  
                <TabContent activeTab={activeApplicationTab} className="p-3">
                  <TabPane tabId={activeApplicationTab} id="home">
                    <Row key={`apptablko${activeApplicationTab}`}>
                      {applications.length > 0 &&
                        <Row>
                          {Object.keys(applications[activeApplicationTab])?.map((key, index) => {
                            if(applicationData[key]){
                              return <>
                                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`apsp${index}`}>
                                  <div className='data-key ms-4'>
                                    {(isRekycOpen) && 
                                      <Input 
                                        style={{border: 'none'}}
                                        className='rekyc-input' 
                                        type='checkbox' 
                                        disabled
                                      />
                                    }
                                    {applicationData[key]}
                                  </div>
                                  <div className='data-value me-4'>{rupeeSymbol?.includes(key) && '₹'}{applications[activeApplicationTab]?.[key]}</div>
                                </div>
                              </>
                            }
                          })}
                          {applications?.[activeApplicationTab]?.additionalData?.map((obj, index) => {
                            if(applicationData[obj?.fieldKey]){
                              return <>
                                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`addData${index}`}>
                                  <div className='data-key ms-4'>
                                    <span>
                                      {(isRekycOpen) && 
                                        <Input 
                                          style={{border: !reKycFieldData?.includes(obj?.fieldKey) && 'none'}}
                                          className='rekyc-input' 
                                          type='checkbox' 
                                          onChange={(e) => handleRekycFieldsSelection(obj?.fieldKey, e.target.checked)} 
                                          disabled={!reKycFieldData?.includes(obj?.fieldKey)}
                                          checked={reKycFields?.[obj?.fieldKey]}
                                        />
                                      }
                                      {applicationData[obj?.fieldKey]}
                                    </span>
                                  </div>
                                  <div className='data-value me-4'>
                                    <span>{rupeeSymbol?.includes(obj?.fieldKey) && '₹'} {obj?.fieldValue || '-'}</span>
                                    {reKycFieldData?.includes(obj?.fieldKey) && 
                                      <ActionDropdown
                                        color={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.color}
                                        className={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.className}
                                        options={fieldVerificationDropdown}
                                        onClick={(action) => handleFieldAction(action, obj?.fieldKey)}
                                      />
                                    }
                                  </div>
                                </div>
                              </>
                            }
                          })}
                          <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                            <Row className='data-key ms-4'>Assigned Team</Row>
                            <Row className='data-value me-4'>
                              {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                                options={teamList}
                                onClick={handleTeamAssignment}
                                value={assignedTo?.teamName || 'None'}
                                handleSearch={fetchTeamList}
                                loading={teamListLoading}
                              /> : <>{assignedTo?.teamName || 'None'}</>}
                            </Row>
                          </div>
                          <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                            <Row className='data-key ms-4'>Assigned To</Row>
                            <Row className='data-value me-4'>
                              {permission?.CUSTOMERS?.DETAILS?.ASSIGN_APPLICATION_TO_USER ? <CustomUncontrolledDropdown
                                options={assignedTeam?.members || []}
                                onClick={handleUserAssignment}
                                value={assignedTo?.userName || 'None'}
                                showSearch={false}
                              /> : <>{assignedTo?.userName || 'None'}</>}
                            </Row>
                          </div>
                        </Row>
                      }
                    </Row>
                    <Row>
                        {applications?.length > 0 && 
                          <>
                            <Nav tabs>
                                {applications?.[activeApplicationTab]?.loan?.map((loan, index) => (
                                  <NavItem key={`loan${index}`}>
                                      <NavLink 
                                        style={{color: activeLoanTab === index && 'green' }} 
                                        className={`${activeLoanTab === index && 'active'} clickable`} 
                                        onClick={toggleLoanTab(index)}
                                      >
                                        <i className="bx bx-coin-stack me-1 align-middle"> </i>{" "}
                                        {loan?.loanUid}
                                      </NavLink>
                                  </NavItem>
                                ))}
                            </Nav>
                            <TabContent activeTab={activeLoanTab} className="p-3">
                              <TabPane tabId={activeLoanTab} id="home">
                                <Row>
                                  {applications?.[activeApplicationTab]?.loan?.length > 0 && <>
                                      {Object?.keys(applications?.[activeApplicationTab]?.loan?.[activeLoanTab]).map((key, index) => {
                                          if(loanData[key]){
                                            return <>
                                              <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={`child${index}`}>
                                                <Row className='data-key ms-4'>{loanData[key]}</Row>
                                                <Row className='data-value me-4'>{rupeeSymbol?.includes(key) && '₹'} {applications?.[activeApplicationTab]?.loan?.[activeLoanTab]?.[key] || '-'}</Row>
                                              </div>
                                            </>
                                          }
                                      })}
                                  </>}
                                </Row>
                                {newLenderList?.length > 0 ? <Row>
                                  <Col xl={12}>
                                    <Table
                                      columns={columns}
                                      loading={loading}
                                      data={newLenderList}
                                      showPagination={false}
                                    />
                                  </Col>
                                </Row> : <Row>
                                    {eligibleLenderLoader ? 
                                    <Row className='mt-4 d-flex justify-content-center'><Spinner /></Row> :
                                    <Row className='mt-4 d-flex justify-content-center'> No Eligible Lender</Row>}
                                  </Row>}
                              </TabPane>
                            </TabContent>
                          </>
                        }
                    </Row>
                    <div className='d-flex flex-direction-row gap-4 justify-content-center flex-wrap mt-4'>
                      {(permission?.ACTIONS?.LOAN) && applications[activeApplicationTab]?.loan?.[activeLoanTab]?.availableActions?.map((obj, index) => {
                        if(permission?.ACTIONS?.LOAN?.[obj?.code]){
                          return (
                            <span key={`actionl${index}`}>
                              <LoadingButton
                                color={obj?.code?.includes('REJECT') ? 'danger' : 'success'}
                                buttonContent={obj?.lable}
                                onClick={handleActionButtonClick(obj?.code)}
                              />
                            </span>
                          )
                        }
                      })}
                      
                      {(permission?.ACTIONS?.SOFTWARE) && applications[activeApplicationTab]?.software?.[0]?.availableActions?.map((obj, index) => {
                        if(permission?.ACTIONS?.SOFTWARE?.[obj?.code] || true){
                          return (
                            <span key={`actions${index}`}>
                              <LoadingButton 
                                color={obj?.code?.includes('REJECT') ? 'danger' : 'success'}
                                buttonContent={obj?.lable}
                                onClick={handleActionButtonClick(obj?.code)}
                              />
                            </span>
                          )
                        }
                      })}
                    </div>
                  </TabPane>
                </TabContent>
              </> 
              }
              </CardBody>
            </Card>
        </>
    )
}

export default Applications;
