import React, { useEffect, useState } from 'react';
import { Modal, Nav, Spinner, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';
import { masterDataKey } from '../../../utils/constants';
import { formatDate2 } from '../../../utils/helper';
import useClass from '../../../hooks/useClass';
import { getApplicationLogs } from '../../../store/application/customer';

const ApplicationLogs = ({isOpen, setIsOpen, applications}) => {

    const { dispatch } = useClass();
    const {data, loading, error} = useSelector((state) => (state?.applicationLogs));
    const [activeApplicationTab, setActiveApplicationTab] = useState(0);

    useEffect(() => {
        dispatch(getApplicationLogs(applications?.[activeApplicationTab]?.applicationId));
    }, [activeApplicationTab]);


    const toggleApplicationTab = (tab) => {
        return () => {
            setActiveApplicationTab(tab);
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => {setIsOpen(!isOpen)} }
                wrapClassName="modal-right"
            >
                <div className="modal-header pb-0">
                    Application Logs - {data?.[0]?.applicationId}
                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='ms-4'>
                    <Nav tabs>
                        {applications?.map((application, index) => (
                            <NavItem key={`application-log${index}`}>
                                <NavLink 
                                    style={{color: activeApplicationTab === index && 'green' }} 
                                    className={`${activeApplicationTab === index && 'active'} clickable`} 
                                    onClick={toggleApplicationTab(index)}
                                >
                                  {application?.applicationId}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeApplicationTab}>
                        <TabPane tabId={activeApplicationTab} id='application-log-tab'>
                            {loading && <div className='text-center'><Spinner /></div>}
                            {data && <div className="wizard-order">
                                <div className="v-line"></div>
                                {data?.map((obj, index) => (
                                    <React.Fragment key={`log-data${index}`}>
                                        <div className="step completed">
                                          <div className='ml-2 data-key' style={{minWidth: '100%'}}>Changed data: {masterDataKey?.[obj?.field]}</div>
                                          <div className='ml-2 data-value' style={{minWidth: '100%'}}>To: {obj?.value}</div>
                                          <div className='ml-2' style={{fontSize: '10px'}}>On: {formatDate2(obj?.createdAt)}, Created By: {obj?.createdBy === 'Admin User' ? 'Customer' : obj?.createdBy}</div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>}
                        </TabPane>
                    </TabContent>
                </div>
            </Modal>
        </>
    )
}

export default ApplicationLogs;
