import React, { useCallback, useState } from 'react';
import TextInput from '../text-input/TextInput';
import MultiSelect from '../Dropdown/Multiselect';
import Dropdown from '../Dropdown/Dropdown';
import { Button } from 'reactstrap';

const DynamicFields = ({data, handleInput, form, handleFieldRemove, index}) => {

    const memoizedHandleInput = useCallback((name, value) => {
        handleInput(name, value);
    }, []);

    const handleMemoizedDropdownInput = useCallback((name, value) => {
        handleInput(name, value?.value);
    }, []);

    const handleMultiSelectInput = useCallback((name, value) => {
        handleInput(name, value);
    }, []);

    const valueKey = (code) => {
        const keys = code?.split(' ');
        const value = keys?.reduce((obj, key) => obj?.[key], form);
        if(data?.type === 'dropdown'){
            if(value !== undefined && value !== ''){
                return {label: value, value: value}
            }
        }
        if(data?.type === 'multiselect'){
            if(value?.length > 0){
                return value?.map((item) => ({label: item?.label, value: item?.value}))
            }
        }
        return value;
    }

    const dynamic = () => {
        switch(data?.type){
            case 'dropdown':
                return <>
                    <Dropdown
                        customClass={'w-100'}
                        name={data?.code}
                        label={data?.label}
                        placeholder={data?.label}
                        options={data?.options}
                        handleInput={handleMemoizedDropdownInput}
                        value={valueKey(data?.code)}
                        isClearable={false}
                        required={true}
                    />
                </>
            case 'multiselect':
                return <>
                    <MultiSelect
                        customClass={'w-100'}
                        name={data?.code}
                        label={data?.label}
                        placeholder={data?.label}
                        options={data?.options}
                        handleInput={handleMultiSelectInput}
                        value={valueKey(data?.code)}
                        required={true}
                    />
                </>
            case 'binary':
                return <>
    
                </>
            default:
                return (
                    <TextInput
                        customClass={'w-100'}
                        value={valueKey(data?.code)}
                        name={data?.code}
                        label={data?.label}
                        handleInput={memoizedHandleInput}
                        type={data?.type}
                        placeholder={data?.label}
                        required={true}
                    />
                )
        }
    }

    return (
        <div className='d-flex align-items-center'>
            {dynamic()}
            {/* <Button style={{marginTop: '20px', position: 'relative', right: '30px'}} close onClick={handleFieldRemove(index)}/> */}
        </div>
    )
}

export default DynamicFields;
