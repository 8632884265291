import React from 'react';
import Data from './Paramjit - Detailed response.json';
import { 
    Button, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader,
    Row,
    Col,
    UncontrolledPopover,
    PopoverBody
} from 'reactstrap';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

const CreditScore = ({isOpen, setIsOpen, name}) => {

    const { report } = useSelector((state) => (state?.bureauReport))
    const creditData = report?.cIRReportData;
    const personalInfo = creditData?.iDAndContactInfo;
    const accountDetails = creditData?.retailAccountDetails;
    const accountSummary = creditData?.retailAccountsSummary;
    const scoreDetails = creditData?.scoreDetails;
    const enquiries = creditData?.enquiries;
    const enquirySummary = creditData?.enquirySummary;
    const recentActivities = creditData?.recentActivities;
    const otherKeyParameters = creditData?.otherKeyInd;

    const chartParams = {
          
        series: [(scoreDetails?.[0]?.value/900)*100],
        options: {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px',
                  formatter: function () {
                    // Display the credit score
                    return scoreDetails?.[0]?.value || 0;
                  },
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91]
            },
          },
          labels: ['Average Results'],
        },
      };

    return (
        <>
            <Modal
                isOpen={isOpen}
                size='xl'
                toggle={() => setIsOpen(!isOpen)}
                centered
                // fullscreen
            >
                <ModalHeader>
                    EQUIFAX Credit Report - <span className='text-primary'>{name}</span>
                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <>
                        <Row>
                            {/* <Col lg="2" md="2" sm="1" xs="1">
                                Score
                            </Col> */}
                            <Col lg="12" md="12" sm="12" xs="12">
                                <ReactApexChart options={chartParams.options} series={chartParams.series} type="radialBar" height={350} />
                            </Col>
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                Personal Information
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                Identification
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                Contact Details
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Name:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.name?.fullName || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Previous Name:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.previousName || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Alias Name:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.aliasName || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>DOB:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.dateOfBirth || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Age:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.age?.age || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Gender:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.gender || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Total Income:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.totalIncome || '-'}</span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Occupation:</span>
                                    <span className='data-value'>{personalInfo?.personalInfo?.occupation || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>PAN:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.pANId?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-pan'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-pan'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.pANId?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Voter ID:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.voterID?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-voter-id'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-voter-id'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.voterID?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Passport ID:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.passportID?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-passport'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-passport'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.passportID?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>UID:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.nationalIDCard?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-uid'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-uid'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.nationalIDCard?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Drivers License:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.driverLicense?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-dl'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-dl'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.driverLicense?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Ration Card:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.rationCard?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-ration-card'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-ration-card'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.rationCard?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>ID Other:</span>
                                    <span className='data-value'>
                                        {personalInfo?.identityInfo?.otherId?.[0]?.idNumber || '-'}&nbsp;
                                        <i id={'score-id-other'} className='ri-information-line' />
                                        <UncontrolledPopover
                                            trigger="hover"
                                            target={'score-id-other'}
                                            placement="top"
                                        >
                                            <PopoverBody>
                                                {personalInfo?.identityInfo?.otherId?.[0]?.reportedDate || 'Date not found'}
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                {personalInfo?.phoneInfo?.map((phone, index) => (
                                    <React.Fragment key={`creditphone${index}`}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>{phone?.typeCode}</span>
                                            <span className='data-value'>{phone?.number || '-'}</span>
                                        </div>
                                    </React.Fragment>
                                ))}
                                {personalInfo?.emailAddressInfo?.map((email, index) => (
                                    <React.Fragment key={`creditemail${index}`}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Email Address:</span>
                                            <span className='data-value'>{email.emailAddress || '-'}</span>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </Col>
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Consumer Address
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Type
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                Address
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                                State
                            </Col>
                            <Col lg={1} md={1} sm={1} xs={1}>
                                Postal
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Date Reported
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            {personalInfo?.addressInfo?.map((address, index) => (
                                <React.Fragment key={`creditaddress${index}`}>
                                    <Col lg={2} md={2} sm={2} xs={2} className='mb-2'>
                                        {address?.type || '-'}
                                    </Col>
                                    <Col lg={6} md={6} sm={6} xs={6} className='mb-2'>
                                        {address?.address || '-'}
                                    </Col>
                                    <Col lg={1} md={1} sm={1} xs={1} className='mb-2'>
                                        {address?.state || '-'}
                                    </Col>
                                    <Col lg={1} md={1} sm={1} xs={1} className='mb-2'>
                                        {address?.postal || '-'}
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} className='mb-2'>
                                        {address?.reportedDate || '-'}
                                    </Col>
                                </React.Fragment>
                            ))}
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Score
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Name
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10}>
                                Value
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {scoreDetails?.[0]?.name}
                            </Col>
                            <Col lg={10} md={10} sm={10} xs={10}>
                                {scoreDetails?.[0]?.value}
                            </Col>
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Recent Activity
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2 text-center'>
                            <Col>
                                Recent Activity(last 90 days)
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Total Inquiries:</span>
                                    <span className='data-value'>{recentActivities?.totalInquiries || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Account Opened:</span>
                                    <span className='data-value'>{recentActivities?.accountsOpened || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Accounts Updated:</span>
                                    <span className='data-value'>{recentActivities?.accountsUpdated || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                <div className='mb-2'>
                                    <span className='data-key me-2'>Accounts Delinquent:</span>
                                    <span className='data-value'>{recentActivities?.accountsDeliquent || '-'}</span>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Summary
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2 text-center'>
                            <Col>
                                Credit Report Summary
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Number of Accounts:</span>
                                    <span className='data-value'>{accountSummary?.noOfAccounts || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Number of Open Accounts:</span>
                                    <span className='data-value'>{accountSummary?.noOfActiveAccounts || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Number of Past Due Accounts:</span>
                                    <span className='data-value'>{accountSummary?.noOfPastDueAccounts || '-'}</span>
                                </div> 
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Number of Write-off Accounts:</span>
                                    <span className='data-value'>{accountSummary?.noOfWriteOffs || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Number of Zero Balance Accounts:</span>
                                    <span className='data-value'>{accountSummary?.noOfZeroBalanceAccounts || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Most Severe Status&lt;24 Months :</span>
                                    <span className='data-value'>{accountSummary?.mostSevereStatusWithIn24Months || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total Balance Amount:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalBalanceAmount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total Past Due Amount:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalPastDue || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total High Credit:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalHighCredit || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total Sanction Amount:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalSanctionAmount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total Monthly Payment Amount:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalMonthlyPaymentAmount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Average Open Balance:</span>
                                    <span className='data-value'>₹ {accountSummary?.averageOpenBalance || '-'}</span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={4} xs={4}>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Recent Account:</span>
                                    <span className='data-value'>{accountSummary?.recentAccount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Oldest Account:</span>
                                    <span className='data-value'>{accountSummary?.oldestAccount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Total Credit Limit:</span>
                                    <span className='data-value'>₹ {accountSummary?.totalCreditLimit || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Single Highest Credit:</span>
                                    <span className='data-value'>₹ {accountSummary?.singleHighestCredit || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Single Highest Sanction Amount:</span>
                                    <span className='data-value'>₹ {accountSummary?.singleHighestSanctionAmount || '-'}</span>
                                </div>
                                <div className='mb-2 d-flex justify-content-start'>
                                    <span className='data-key me-2'>Single Highest Balance:</span>
                                    <span className='data-value'>₹ {accountSummary?.singleHighestBalance || '-'}</span>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Account Details
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2 text-center'>
                            <Col>
                                Accounts
                            </Col>
                        </Row>
                        {accountDetails?.map((account, index) => (
                            <Row className='p-2 mb-2' key={`creditaccounts${index}`} style={{border: '1px solid black', borderTop: '5px solid cornsilk', backgroundColor: 'aliceblue'}}>
                                <Row>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Acc Number:</span>
                                            <span className='data-value'>{account?.accountNumber || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Institution:</span>
                                            <span className='data-value'>{account?.institution || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Type:</span>
                                            <span className='data-value'>{account?.accountType || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Ownership Type:</span>
                                            <span className='data-value'>{account?.ownershipType || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Balance:</span>
                                            <span className='data-value'>₹ {account?.balance || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Past Due Amount:</span>
                                            <span className='data-value'>₹ {account?.pastDueAmount || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Last Payment:</span>
                                            <span className='data-value'>₹ {account?.lastPayment || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Write-off Amouny:</span>
                                            <span className='data-value'>₹ {account?.writeOffAmount || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Institution Type:</span>
                                            <span className='data-value'>₹ {account?.institutionType || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Open:</span>
                                            <span className='data-value'>{account?.open || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Interest Rate:</span>
                                            <span className='data-value'>{account?.interestRate || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Last Payment Date:</span>
                                            <span className='data-value'>{account?.lastPaymentDate || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Sanction Amount:</span>
                                            <span className='data-value'>₹ {account?.sanctionAmount || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Date Reported:</span>
                                            <span className='data-value'>{account?.dateReported || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Date Opened:</span>
                                            <span className='data-value'>{account?.dateOpened || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Date Closed:</span>
                                            <span className='data-value'>{account?.dateClosed || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Reason:</span>
                                            <span className='data-value'>{account?.reason || '-'}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{border: '2px dashed lightblack'}} />
                                <Row>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Repayment Tenure:</span>
                                            <span className='data-value'>{account?.repaymentTenure || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Dispute Code:</span>
                                            <span className='data-value'>{account?.disputeCode || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Monthly Payment Amount:</span>
                                            <span className='data-value'>₹ {account?.installmentAmount || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Term Frequency:</span>
                                            <span className='data-value'>{account?.termFrequency || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Credit Limit:</span>
                                            <span className='data-value'>₹ {account?.creditLimit || '-'}</span>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Collateral Value:</span>
                                            <span className='data-value'>{account?.collateralValue || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Collateral Type:</span>
                                            <span className='data-value'>{account?.collateralType || '-'}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{border: '2px dashed lightblack'}} />
                                <Row>
                                    <Col lg={4} md={4} sm={4} xs={4}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Account Status:</span>
                                            <span className='data-value'>{account?.accountStatus || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Asset Classification:</span>
                                            <span className='data-value'>{account?.assetClassification || '-'}</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Suit Filled Status:</span>
                                            <span className='data-value'>{account?.suiteFilledStatus || '-'}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <hr style={{border: '2px dashed lightblack'}} />
                                <Row>
                                    <Col lg={3} md={3} sm={2} xs={2}>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>History:</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Account Status:</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Asset Classification:</span>
                                        </div>
                                        <div className='mb-2'>
                                            <span className='data-key me-2'>Suit Filled Status:</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} md={9} sm={10} xs={10}>
                                        <Row className='d-flex' style={{overflow: 'scroll'}}>
                                            <table>
                                                <tr>
                                                    {account?.history48Months?.map((history, index) => (
                                                        <td className='p-2' key={`credithistory-paymentStatus-${index}`} style={{border: '1px solid black'}}>
                                                          <span className='data-value'>{history?.paymentStatus}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {account?.history48Months?.map((history, index) => (
                                                        <td className='p-2' key={`credithistory-key-${index}`} style={{border: '1px solid black'}}>
                                                          <span className='data-value'>{history?.key}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {account?.history48Months?.map((history, index) => (
                                                        <td className='p-2' key={`credithistory-assetClassificationStatus-${index}`} style={{border: '1px solid black'}}>
                                                          <span className='data-value'>{history?.assetClassificationStatus}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    {account?.history48Months?.map((history, index) => (
                                                        <td className='p-2' key={`credithistory-suitFiledStatus-${index}`} style={{border: '1px solid black'}}>
                                                          <span className='data-value'>{history?.suitFiledStatus}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </table>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        ))}
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Inquiry Summary
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Purpose
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Total
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Past 30 Days
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Past 12 Months
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Past 24 Months
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Recent
                            </Col>
                        </Row>
                        <Row className='p-2'>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.purpose}
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.total}
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.past30Days}
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.past12Months}
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.past24Months}
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                {enquirySummary?.recent}
                            </Col>
                        </Row>
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Inquiries
                        </Row>
                        <Row style={{backgroundColor: 'cornsilk'}} className='p-2'>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Institution
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Date
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Time
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Purpose
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>
                                Amount
                            </Col>
                        </Row>
                        {enquiries?.map((enquiry, index) => (
                            <Row className='p-2' key={`enquiry${index}`}>
                                <Col lg={3} md={3} sm={3} xs={3}>
                                    {enquiry?.institution}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {enquiry?.date}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {enquiry?.time}
                                </Col>
                                <Col lg={3} md={3} sm={3} xs={3}>
                                    {enquiry?.requestPurpose}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {enquiry?.amount}
                                </Col>
                            </Row>
                        ))}
                        <hr />
                        <Row className='p-2' style={{fontSize: '16px', fontWeight: 'bold'}}>
                            Consumer Registered Disputes
                        </Row>
                        <Row className='p-2' style={{backgroundColor: 'cornsilk'}}>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Dispute Type
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Reason For Dispute
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Account Number
                            </Col>
                            <Col lg={3} md={3} sm={3} xs={3}>
                                Dispute Raised Date
                            </Col>
                        </Row>
                        <Row className='p-2'>

                        </Row>
                    </>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color='success' onClick={() => setIsOpen(!isOpen)}>OK</Button>
                </ModalFooter> */}
            </Modal>
        </>
    )
}

export default CreditScore