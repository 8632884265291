import React, { useState } from 'react';
import { Modal } from "reactstrap";
import Rekyc from './Rekyc';
import Default from './Default';
import { useSelector } from 'react-redux';
import useClass from '../../../hooks/useClass';
import { updateApplicationAction } from '../../../store/application/applicationAction';

const CustomerModal = (props) => {

  const {
    isOpen, 
    setIsOpen,
    customerCode, 
    isRekycOpen, 
    reKycFields, 
    applicationList,
    handleRekycFieldsSelection,
    handleFieldAction
  } = props;

  const { dispatch } = useClass();
  const { action } = useSelector((state) => (state?.loanAction?.data));
  const { loading } = useSelector((state) => (state?.applicationAction));

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleAction = (payload) => {
    setIsOpen(false);
    dispatch(updateApplicationAction(payload));
  }

  const ActionForm = () => {
    switch(action){
      case 'QC_ON_HOLD':
        return <>
          <Rekyc
            reKycFields={reKycFields}
            handleRekycFieldsSelection={handleRekycFieldsSelection}
            handleModelClose={handleModelClose}
            isLoading={loading}
            handleAction={handleAction}
            customerCode={customerCode}
            handleFieldAction={handleFieldAction}
          />
        </>
      default:
        return <>
          <Default
            applicationList={applicationList}
            handleModelClose={handleModelClose}
            isLoading={loading}
            handleAction={handleAction}
          />
        </>
    }
  }

  return (
    <>
      <Modal 
        isOpen={isOpen}
        size={((action === 'QC_ON_HOLD' || action === "INVOICE_GENERATE") && 'lg') || 'md'}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
      >
        <div className="modal-header">
          { action !== "INVOICE_GENERATE" ? <h5 className="modal-title mt-0">
            {isRekycOpen ? 'Select reason for each field' : 'Add Comment'}
          </h5> : <h5>Invoice Preview</h5>}

          <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ActionForm />
      </Modal>
    </>
  )
}

export default CustomerModal;
