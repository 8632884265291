import React from 'react';
import { Modal } from 'reactstrap';

const RevenueBreakup = ({isOpen, setIsOpen, data}) => {



    return (
    <>
        <Modal
            isOpen={isOpen}
            size={'md'}
            toggle={() => {setIsOpen(!isOpen)} }
            centered
        >
            <div className="modal-header">
                Revenue Breakup
                <button type="button" onClick={() => setIsOpen(!isOpen)} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className='p-4'>
                {data?.map((obj, index) => (<React.Fragment key={`revenuebreakup${index}`}>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Customer</div>
                        <div className='data-value'>{obj?.customer}</div>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Type of vehicle deployed</div>
                        <div className='data-value'>{obj?.typeOfVehicleDeployed}</div>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Manufacturer</div>
                        <div className='data-value'>{obj?.manufacturer}</div>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Number of vehicle deployed</div>
                        <div className='data-value'>{obj?.numberOfVehicleDeployed}</div>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Avg. Kms driven/month</div>
                        <div className='data-value'>{obj?.avgKmDrivenPerMonth}</div>
                    </div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='data-key'>Contract Value</div>
                        <div className='data-value'>{obj?.contractValue}</div>
                    </div>
                    <hr/ >
                </React.Fragment>))}
            </div>
        </Modal>
    </>
  )
}

export default RevenueBreakup;