import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomSelectInput from './CustomSelectInput';
import { FormGroup, Label } from 'reactstrap';

const Dropdown = (props) => {

  const {
    label, 
    name, 
    value, 
    handleInput,
    disabled,
    required,
    placeholder,
    customClass,
    options,
    isClearable=true
  } = props
  // const [selectedOption, setSelectedOption] = useState('');
  
  // useEffect(() => {
  //   if(value?.trim() !== '' && value != undefined){
  //     setSelectedOption({label: value});
  //   }
  // }, [value])

  const handleChange = (obj) => {
    // setSelectedOption(obj);
    handleInput(name, obj);
  }
  

  return (
    <>
        <div className={customClass}>
          <FormGroup className="form-group has-float-label">
            {/* <div className="d-block">
              {label}<span style={{color: 'red'}}>{required && '*'}</span>
            </div> */}
            <Select
              components={{ Input: CustomSelectInput }}
              className="react-select"
              classNamePrefix="react-select"
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={handleChange}
              options={options}
              required={required}
              isDisabled={disabled}
              isClearable={isClearable}
            />
          </FormGroup>
        </div>
    </>
  );
};
export default Dropdown;