import { configureStore } from '@reduxjs/toolkit';
import { permissionsKeyPairReducer, userDetailsReducer, userPermissionsReducer } from './slices/userSlice';
import { 
  agreementReducer,
  lenderListReducer,
  partnerReducer, 
  portalUserReducer, 
  productReducer, 
  productSchemeReducer, 
  roleReducer,
  teamReducer,
  userReducer
} from './admin/adminData';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';
import { hotLeadReducer, notificationReducer, notificationTemplateReducer, notificationTypeReducer, taskListReducer } from './user/userSlice';
import { bureauReportReducer, loanActionReducer } from './loan/loanAction';
import { applicationActionFieldsReducer, applicationActionReducer } from './application/applicationAction';
import { applicationLogsReducer, customerApplicationReducer, eligibleLendersReducer } from './application/customer';
import { customerPaymentsReducer } from './application/payment';
import { customerEmiScheduleReducer } from './application/emiSchedule';
import { batteryReducer, chargerReducer, chargingStationReducer, manufactureReducer, vehicleReducer } from './oem/oemData';
import { dashboardReducer } from './dashboard/dashboard';
import { campaignTemplateReducer } from './campaign/template';
import { campaignSegmentReducer } from './campaign/segment';
import { campaignFlowReducer } from './campaign/campaignFlow';
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    userDetails: userDetailsReducer,
    userPermissions: userPermissionsReducer,
    permissionPair: permissionsKeyPairReducer,
    loanAction: loanActionReducer,
    role: roleReducer,
    manufacture:manufactureReducer,
    vehicle:vehicleReducer,
    battery:batteryReducer,
    charger:chargerReducer,
    chargingStation:chargingStationReducer,
    lender: lenderListReducer,
    partner: partnerReducer,
    user: userReducer,
    task: taskListReducer,
    hotLead: hotLeadReducer,
    team: teamReducer,
    product: productReducer,
    applicationAction: applicationActionReducer,
    actionFields: applicationActionFieldsReducer,
    productScheme: productSchemeReducer,
    customer: customerApplicationReducer,
    agreement: agreementReducer,
    portalUser: portalUserReducer,
    payments:customerPaymentsReducer,
    emiScedule:customerEmiScheduleReducer,
    dashboardData:dashboardReducer,
    notification: notificationReducer,
    bureauReport: bureauReportReducer,
    campaignTemplate:campaignTemplateReducer,
    campaignSegment: campaignSegmentReducer,
    campaignFlow: campaignFlowReducer,
    eligibleLenders: eligibleLendersReducer,
    applicationLogs: applicationLogsReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(sagas);
