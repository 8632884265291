import React from 'react';
import Select from 'react-select';
import CustomSelectInput from './CustomSelectInput';
import { FormGroup, Label } from 'reactstrap';

const MultiSelect = (props) => {

  const {
    label, 
    name, 
    value,
    handleInput,
    disabled,
    required,
    placeholder,
    customClass,
    options
  } = props;

  const handleChange = (value) => {
    handleInput(name, value);
  }

  return (
    <>
      <div className={customClass}>
        <FormGroup className="form-group has-float-label">
          {/* <Label className="d-block">
            {label}
          </Label> */}
          <Select
            components={{ Input: CustomSelectInput }}
            className="react-select"
            classNamePrefix="react-select"
            isMulti
            name={name}
            value={value}
            onChange={handleChange}
            options={options}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
          />
        </FormGroup>
      </div>
    </>
  )
}

export default MultiSelect
