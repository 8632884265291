import { createSlice } from "@reduxjs/toolkit";
import { initialFieldStatusConstant } from "../../utils/constants";

export const applicationAction = createSlice({
  name: 'applicationAction',
  initialState: {
    activeApplicationIndex: 0,
    applicationList: [],
    data: {},
    loading: false,
    error: null
  },
  reducers: {
    updateActiveApplicationIndex: (state, action) => {
      state.activeApplicationIndex = action?.payload
    },
    updateApplicationList: (state, action) => {
      state.applicationList = action?.payload;
    },
    updateApplicationAction: (state, action) => {
      state.loading = true;
    },
    updateApplicationActionSuccess: (state, action) => {
      state.loading = false;
      state.data = action?.payload
    },
    updateApplicationActionError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
});



export const applicationActionFields = createSlice({
  name: 'applicationActionFields',
  initialState: {
    reKycFields: {},
    taskFields: {},
    fieldStatus: initialFieldStatusConstant,
    loading: false,
    error: null
  },
  reducers: {
    updateRekycFields: (state, action) => {
      state.reKycFields = action?.payload
    },
    updateTaskFields: (state, action) => {
      state.taskFields = action?.payload
    },
    getApplicationFieldStatus: (state, action) => {
      state.loading = true;
    },
    getApplicationFieldStatusSuccess: (state, action) => {
      state.fieldStatus = {...state?.fieldStatus, ...action?.payload};
      state.loading = false;
    },
    getApplicationFieldStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    }
  }
})

export const saasAction = createSlice({
  name: 'saasAction',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    updateSaasApplicationStatus: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    updateSaasApplicationStatusSuccess: (state, action) => {
      state.loading = false;
    },
    updateSaasApplicationStatusError: (state, action) => {
      state.loading = false;
      state.error = action?.payload
    }
  }
})


export const { 
  updateActiveApplicationIndex,
  updateApplicationList,
  updateApplicationAction, 
  updateApplicationActionSuccess, 
  updateApplicationActionError,
} = applicationAction?.actions;
export const { 
  updateRekycFields, 
  updateTaskFields,
  getApplicationFieldStatus,
  getApplicationFieldStatusSuccess,
  getApplicationFieldStatusError
} = applicationActionFields?.actions; 
export const {
  updateSaasApplicationStatus,
  updateSaasApplicationStatusSuccess,
  updateSaasApplicationStatusError
} = saasAction?.actions;

export const applicationActionReducer = applicationAction?.reducer;
export const applicationActionFieldsReducer = applicationActionFields?.reducer;
export const saasReducer = saasAction?.reducer;