import React, { useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Common/Table';
import CustomerTableColumns from './CustomerTableColumns';
import useClass from '../../hooks/useClass';
import Filters from '../../components/Common/Filters';
import { getCustomerApplicationList } from '../../store/application/customer';
import { useSelector } from 'react-redux';

const Customers = () => {
    document.title = "Customers | TapFin";

    const {permission, dispatch} = useClass();
    const { list, totalList, loading } = useSelector((state) => (state?.customer));
    const [pagination, setPagination] = useState({page: 1, perPage: 20});
    const columns = CustomerTableColumns();

    const getCustomerList = (params) => {
        const { pagination, searchText, dateRange, type } = params;
        dispatch(getCustomerApplicationList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange, type}))
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Applications" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-end'>
                                    {/* <h5 className="card-title mb-0">Applications List</h5> */}
                                    <Filters
                                        fetchDataFromServer={getCustomerList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showType={true}
                                        typeOptions={[{
                                            label: 'Individual',
                                            value: 'Individual'
                                          },
                                          {
                                            label: 'Public Limited',
                                            value: 'Public Limited'
                                          },
                                          {
                                            label: 'Private Limited',
                                            value: 'Private Limited'
                                          }, 
                                          {
                                            label: 'Sole proprietor',
                                            value: 'Sole proprietorship'
                                          }, 
                                          {
                                            label: 'LLP',
                                            value: 'LLP'
                                          }]}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table 
                                        data={list} 
                                        loading={loading}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Customers;